.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but9{
    color: #fff;
    background-color:#2D75E0;
    border: none;
    border-radius: .5vw;
}
.line{
    border-width: .2vw;
}
.line2{
    border-width: .3vw;
    border: 4px solid #47C6EE;
    border-radius: 20px ;
}