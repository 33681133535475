.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but25{
    color: #fff;
    background-color:#2D75E0;
    border: none;
    border-radius: .5vw;
}