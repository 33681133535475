.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but30{
    color: #fff;
    background-color:#EA2963;
    border-radius: .5vw;
    border: none;
}

.logo{
    width: 9vw;
}
@media only screen and (max-width:992px) {
    .logo{
        width: 10vw;
    }
}@media only screen and (max-width:768px) {
    .logo{
        width: 15vw;
    }
}
@media only screen and (max-width:576px) {
    .logo{
        width: 15vw;
    }
}
.button22 {
    padding: 5px 10px;
    margin: 0 5px;
    font-size: 16px;
  }
  
  .span2 {
    margin: 0 10px;
    font-size: 16px;
  }

  .minus{
    
  }