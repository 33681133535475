.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but12{
    color: #fff;
    background-color:#2D75E0;
    border: none;
    border-radius: .5vw;
}

.logo{
    width: 9vw;
}
@media only screen and (max-width:992px) {
    .logo{
        width: 10vw;
    }
}@media only screen and (max-width:768px) {
    .logo{
        width: 15vw;
    }
}
@media only screen and (max-width:576px) {
    .logo{
        width: 15vw;
    }
}