.App {
  text-align: center;
}
.logo{
  width: 9vw;
}
@media only screen and (max-width:992px) {
  .logo{
      width: 10vw;
  }
}@media only screen and (max-width:768px) {
  .logo{
      width: 15vw;
  }
}
@media only screen and (max-width:576px) {
  .logo{
      width: 15vw;
  }
}