.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but4{
    color: #fff;
    background-color:#EA2963;
    border: none;
    border-radius: .5vw;
}
.male{
    transform: translateY(-5px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 2vw;
}
.female
{
    transform: translateY(-5px);
   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 2vw;
}