.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but23{
    color: #fff;
    background-color:#598E2E;
    border: none;
    border-radius: .5vw;
}