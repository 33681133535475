.backcolou{
    background-color: #F1F5F9;
}
.menuu{
  background-color: white;
}
.lang{
  background-color: #F1F5F9;
  border: none;
}
.backgroundtheme{
    background-color: black;
}
.footerlog{
    width: 50vw;
}
.testimony-container {
  background-color: #F1F5F9;
  padding: 50px 20px;
}

.testimony-title {
  text-align: center;
  font-weight: bold;
  color: #2c2e50;
  margin-bottom: 20px;
}

.testimony-card {
  border: none;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimony-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.rating {
  color: #ffc107;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #000;
  border-radius: 50%;
}

.butsub{
  border-radius: 1vw;
  background-color: #4175FB;
  border: none;
  color: white;
}
.pol{
  background: #F1F5F9;
  border-radius: 1vw;
  cursor: pointer;
}
.pol:hover{
background-color: #003E74;
color: #fff;
fill:#fff;
}
/* Icon Container */
/* Icon Container */
.iconss-container {
    background-color: #001f54; /* Dark blue color */
    border-radius: 50%;
    width: 70px; /* Circle size */
    height: 70px; /* Circle size */
    display: flex;
    align-items: center; /* Vertically align the icon */
    justify-content: center; /* Horizontally align the icon */
    position: relative;
    top: -2.5vw; /* Adjust spacing above card for responsiveness */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .iconss-container img {
    width: 30px; /* Adjust the size of the icon */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Card Styles */
  .cards {
    border-radius: 15px; /* Rounded corners */
    border: none;
    padding-top: 20px; /* Avoid overlap of the icon container */
  }
  
  .cardss-title {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .cardss-text {
    font-size: 0.95rem;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .iconss-container {
      width: 60px;
      height: 60px;
      top: -4vw; /* Adjust positioning for smaller screens */
    }
  
    .iconss-container img {
      width: 25px; /* Reduce icon size for smaller screens */
    }
  
    .cardss-title {
      font-size: 1rem; /* Adjust font size */
    }
  
    .cardss-text {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 576px) {
    .iconss-container {
      width: 50px;
      height: 50px;
      top: -5vw; /* Further adjust position for extra small screens */
    }
  
    .iconss-container img {
      width: 20px;
    }
  
    .cardss-title {
      font-size: 0.9rem;
    }
  
    .cardss-text {
      font-size: 0.8rem;
    }
  }
  

  .section-heading {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    color: #001f54; /* Dark Blue */
  }

  .card {
    border: none;
    border-radius: 15px;
    padding: 2rem;
    text-align: center;
    background-color: #f8f9fa; /* Light Gray */
  }

  .icon-container {
    width: 120px;
    height: 120px;
    margin: 0 auto 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eef3fa; /* Light Blue Background */
  }

  .icon-container img {
    width: 60px;
    height: auto;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
    color: #001f54; /* Dark Blue */
  }

  .card-text {
    font-size: 0.9rem;
    color: #6c757d; /* Muted Text */
    margin-bottom: 1.5rem;
  }

  .btn-custom {
    color: #001f54;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid #001f54;
  }

  .btn-custom:hover {
    text-decoration: none;
    color: #003580; /* Slightly Darker Blue */
  }


 
  
  .mobile-card {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .mobile-icon-container img {
    max-width: 80px;
    height: auto;
  }
  
  .mobile-card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .mobile-card-text {
    font-size: 0.9rem;
    color: #6c757d;
  }
  
  .mobile-btn-custom {
    margin-top: 10px;
    text-transform: uppercase;
  }
  
  