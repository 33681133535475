.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but19{
    color:white;
    background-color: #47C6EE;
    border-radius: 2vw;
    border: none;
}