.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.but7{
    color: #fff;
    background-color:#2CA92A;
    border: none;
    border-radius: .5vw;
}