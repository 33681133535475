/* .slide-in {
    transition: transform 0.3s ease-out;
    transform: translateX(0%);
  }
  
  @media (max-width: 991.98px) {
    .slide-in {
      transform: translateX(-100%);
    }
  }
   */
.get{
    background-color: #4175FB;
    border-radius: 13px;
    color: white;
    width: 7vw; 
}
.get:hover{
   color: white;
}
.hero{
    text-align: justify;
}


/* .change{
  background-color: white;
} */


.background-image {
  background-image: url('../resources/png_bg.png');
  background-size: cover;
  background-repeat: no-repeat;

}

.kno{
  color: #4175FB;
}

.background-imag1 {
  background-image: url('../resources/png_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  
   
}
.buy{
  border-radius: 1vw;
  border: 2px solid #986332 ;
  background-color:#FFFBF6;
}
.in{
  color: #986332 ;
}
.day{
  background-color:#FFF4F9;
  border-radius: 1vw;
  border: 2px solid  #983263;
}
.night{
  color: #983263;
}
.other{
  background-color:#F8F8F8;
  border-radius: 1vw;
  border: 2px solid#787878
  ;
}
.pt{
  color:#787878;
}
.bi{
  color: #324F98;
}
.bi2{
  background-color: #FAFBFF ;
  border-radius: 1vw;
  border: 2px solid #324F98;
}
.happy{
  border-radius: 2vw;
  border: 1px solid black;
}

.policy1{
    border-radius:1vw ;
    background-color: #FFFFFF
    ;
}
.ren{
  text-align: justify;
}
.list{
  cursor: pointer;
}
.but{
  background-color:#4175FB ;
  border-radius: 1vw;
  color: #FFFFFF;
}
.logo{
  filter: invert (100%)
}
.cad{
  border-bottom-right-radius: 2vw;
  border-top-right-radius: 2vw;
  border-left: 12px solid #A1AAA8
  ;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px ; */
  border-radius: 10px;
  /* box-shadow: .3px .6px .6px 1px #92C1E3; */
  box-shadow: #92C1E3 0px 1px 8px;
}
.best{
    color: #4175FB;
}

/* .carousel-inner {
  padding: 1em;
}
.card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
}
.carousel-control-prev,
.carousel-control-next {
  background-color: #e1e1e1;
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .carousel-item {
    margin-right: 0;
    flex: 0 0 33.333333%;
    display: block;
  }
  .carousel-inner {
    display: flex;
  }
}
.card .img-wrapper {
    max-width: 100%;
    height: 13em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card img {
    max-height: 100%;
}
@media (max-width: 767px) {
  .card .img-wrapper {
    height: 17em;
  }
} */

.insure{
  height: 2vw;
}

@media only screen and (max-width:992px) {
  .insure{
    height: 3vw;
}
}

@media only screen and (max-width:768px) {
  .insure{
      height: 3vw;
  }
}

@media only screen and (max-width:576px) {
  .insure{
    height: 5vw;
} 
}

.cardcontainer{
  width: 350px;
  height: 500px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}
/* .cardcontainer:hover{
  box-shadow: 0 0 45px gray;
} */
.photo{
  height: 300px;
  width: 100%;
}
.photo img{
  height: 100%;
  width: 100%;
}
.txt1{
  margin: auto;
  text-align: center;
  font-size: 17px;
}
.content{
  width: 80%;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: -33px;
}
.photos{
  width: 90px;
  height: 30px;
  background-color: #E74C3C;
  color: white;
  position: relative;
  top: -30px;
  padding-left: 10px;
  font-size: 20px;
}
.txt4{
  font-size:27px;
  position: relative;
  top: 33px;
}
.txt5{
  position: relative;
  top: 18px;
  color: #E74C3C;
  font-size: 23px;
}
.txt2{
  position: relative;
  top: 10px;
}
.cardcontainer:hover > .photo{
  height: 200px;
  animation: move1 0.5s ease both;
}
@keyframes move1{
  0%{height: 300px}
  100%{height: 200px}
}
.cardcontainer:hover > .content{
  height: 200px;
}
.footer{
  width: 80%;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  position: relative;
  top: -15px;
}
.btn{
  position: relative;
  top: 20px;
}
#heart{
  cursor: pointer;
}
.like{
  float: right;
  font-size: 22px;
  position: relative;
  top: 20px;
  color: #333333;
}
.fa-gratipay{
  margin-right: 10px;
  transition: 0.5s;
}
.fa-gratipay:hover{
  color: #E74C3C;
}
.txt3{
  color: gray;
  position: relative;
  top: 18px;
  font-size: 14px;
}
.comments{
  float: right;
  cursor: pointer;
}
.fa-clock, .fa-comments{
  margin-right: 7px;
}
.back{
  background-color: #003E74;
}

/* .baner{
  background-image: url('../resources/insurance-partners.png');
  background-size: cover;
  background-repeat: no-repeat;
} */