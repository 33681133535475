.background-imag1 {
    background-image: url('../resources/png_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.self{
    /* box-shadow: ; */
    background-color: #fff;
}
.but31{
    color: #fff;
    background-color:#EA2963;
    border-radius: .5vw;
    border: none;
}